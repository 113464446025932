import logo from "./logo.svg";
import "./App.css";

import React, { useEffect, useRef, useState } from "react";
import { BANUBA_CLIENT_TOKEN } from "./BanubaClientToken";
import { Webcam, Player, Module, Effect, Dom } from "@banuba/webar";

import wasm from "@banuba/webar/BanubaSDK.wasm";
import simd from "@banuba/webar/BanubaSDK.simd.wasm";
import data from "@banuba/webar/BanubaSDK.data";
// import BackgroundPicture from "./effects/BackgroundPicture.zip";

// Find more about available modules:
// https://docs.banuba.com/face-ar-sdk-v1/generated/typedoc/classes/Module.html
import FaceTracker from "@banuba/webar/face_tracker.zip";
import Background from "@banuba/webar/background.zip";
import Skin from "@banuba/webar/skin.zip";
import Correctors from "@banuba/webar/correctors.zip";
import Eyes from "@banuba/webar/eyes.zip";
import Body from "@banuba/webar/body.zip";
import Lips from "@banuba/webar/lips.zip";
import Picture from "./Picture";

function App() {
  const ref = useRef({});
  const imgRef = useRef({});
  const [globalPlayer, setGlobalPlayer] = useState(null);
  const [currentEFfect, setCurrentEffect] = useState([]);
  const [showToast, setShowToast] = useState(false);

  const effects = [
    "Brightening Serum",
    "Hydrating Serum",
    "Firming Serum",
    "Exfoliating Serum",
    "Anti Aging Serum",
  ];

  const addEffect = async (effectName) => {
    if (effectName === currentEFfect) {
      globalPlayer.clearEffect();
    } else {
      setCurrentEffect(effectName);
      globalPlayer.clearEffect();

      // LOAD EFFECT
      const effect = await Effect.preload(`effects/makeup.zip`);
      // globalPlayer.clearEffect();
      // APPLY EFFECT ON PLAYER
      await globalPlayer.applyEffect(effect);
      await effect.evalJs("Skin.softening(0.2)");
      // await globalPlayer.applyEffect(new Effect(`effects/${effectName}.zip`));

      // APPLY FILTERS FOR CURRENT DESIRED EFFECT
      if (effectName === "Brightening Serum") {
        await effect.evalJs("Skin.color('0.8 0.6 0.1 0.4')");
        await effect.evalJs("Skin.softening(1)");
        // await effect.evalJs("Eyes.whitening(1)");
      } else if (effectName === "Hydrating Serum") {
        // set skin color
        await effect.evalJs("Skin.color('0.73 0.39 0.08 0.3')");
        // set softening strength (skin smoothing)
        await effect.evalJs("Skin.softening(1)");
      } else if (effectName === "Firming Serum") {
        // set skin color
        await effect.evalJs("Softlight.strength(0.5)");
        await effect.evalJs("FaceMorph.eyes(0.6,0,0,0,0,0,0)");
        await effect.evalJs("FaceMorph.face(0.5,0,0,0,0,0,0,0,0)");
        await effect.evalJs("FaceMorph.nose(1,0,0)");
        await effect.evalJs("FaceMorph.lips(1,0,0,0,0,0)");
        await effect.evalJs("FaceMorph.nose('0.9')");
      } else if (effectName === "Exfoliating Serum") {
        await effect.evalJs("Makeup.highlighter('0.75 0.74 0.74 0.4')");
        await effect.evalJs("Skin.color('0.73 0.39 0.08 0.3')");
        await effect.evalJs("Skin.softening(1)");
        await effect.evalJs("Softlight.strength(0.7)");
        // await effect.evalJs("Lips.matt('0.85 0.43 0.5 0.6')");
      } else {
        await effect.evalJs("Skin.softening(1)");
      }
      // // setShowToast(true);
      // // setTimeout(() => setShowToast(false), [2000]);
      // }
      Dom.render(globalPlayer, "#webar");
    }
  };

  const takePicture = () => {
    console.log("function is taking picture");
    let canvas = document.querySelector("#webar canvas");
    let picture = document.getElementById("picture");
    console.log(canvas);
    // canvas
    //   .getContext("2d")
    //   .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    picture.style.width = "600px";
    picture.style.height = canvas.style.height;
    picture.src = canvas.toDataURL();
    console.log(canvas.toDataURL());
    // Stop the video stream and remove the video and canvas elements
    // videoStream.getTracks()[0].stop();
    // video.remove();
    canvas.remove();
  };

  // componentDidMount
  useEffect(() => {
    const webcam = (ref.current.webcam ??= new Webcam());
    const promise = (ref.current.player ??= Player.create({
      clientToken: BANUBA_CLIENT_TOKEN,
      /**
       * By default BanubaSDK.js tries to loads BanubaSDK.wasm and BanubaSDK.data files relative to itself.
       * Since the BanubaSDK.js will be bundled to something like `static/js/[name].[hash].js` during a build
       * and the BanubaSDK.wasm and BanubaSDK.data files may not lay next to the bundle file
       * we have to tell the BanubaSDK where it can find these BanubaSDK.wasm and BanubaSDK.data files.
       * @see {@link https://docs.banuba.com/generated/typedoc/globals.html#sdkoptions} further information}
       */
      locateFile: {
        "BanubaSDK.wasm": wasm,
        "BanubaSDK.simd.wasm": simd,
        "BanubaSDK.data": data,
      },
    }).then((player) =>
      player
        .addModule(
          new Module(FaceTracker),
          new Module(Background),
          new Module(Skin),
          new Module(Correctors),
          new Module(Eyes),
          new Module(Body),
          new Module(Lips)
        )
        .then(() => {
          player.use(webcam);
          //   console.log("webcam", webcam);
          setGlobalPlayer(player);
          // player.applyEffect(new Effect("effects/makeup.zip"));
          Dom.render(player, "#webar");
          //   // console.log(player);
        })
    ));

    // return () => {
    //   webcam.stop();
    //   Dom.unmount("#webar");
    // };
  });

  return (
    <div className="App">
      <div className="webar-flex">
        <div>
          <div id="webar" style={{ maxWidth: "600px" }}></div>
          <img alt="" style={{ objectFit: "cover" }} id="picture" />
        </div>
        <div className="webar-btns">
          {effects.map((effect) => {
            return (
              <button
                onClick={() => {
                  addEffect(effect);
                }}
                key={effect}
              >
                {effect}
              </button>
            );
          })}
        </div>
        {/* <Picture imgRef={imgRef} /> */}
      </div>
      <button
        className="take-picture-btn"
        onClick={() => {
          takePicture();
        }}
      >
        Take picture
      </button>
      {showToast ? (
        <div>
          {" "}
          {currentEFfect} {"Effect applied"}
        </div>
      ) : null}
    </div>
  );
}

export default App;
